import { createRouter, createWebHistory, createWebHashHistory } from "vue-router"

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import('@/views/index')
    },
    {
        path: "/person",
        name: "person",
        component: () => import('@/views/person')
    },
    {
        path: "/album_list",
        name: "album_list",
        component: () => import('@/views/album_list')
    },
    {
        path: "/detail",
        name: "detail",
        component: () => import('@/views/detail')
    }
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes: routes
})

export default router
